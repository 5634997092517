import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, Container, CssBaseline } from '@mui/material';
import CFDCalculator from './CFDCalculator';
import Homepage from './Homepage';
import ShowChartIcon from '@mui/icons-material/ShowChart'; // Stock chart icon

const App = () => {
  return (
    <Router>
      <CssBaseline />
      <AppBar position="static" sx={{ backgroundColor: '#003366' }}>
        <Toolbar>
          <ShowChartIcon sx={{ marginRight: 1, color: '#66BB6A' }} />
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontWeight: 'bold' }}>
            StonkBlade
          </Typography>
          <Button color="inherit" component={Link} to="/">Home</Button>
          <Button color="inherit" component={Link} to="/calculator">CFD Calculator</Button>
          <Button color="inherit">Contact</Button>
          <Button color="inherit">About</Button>
        </Toolbar>
      </AppBar>
      <Container sx={{ marginTop: 4 }}>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/calculator" element={<CFDCalculator />} />
        </Routes>
      </Container>
    </Router>
  );
};

export default App;
