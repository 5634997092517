import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, Typography, Tabs, Tab, Box, TextField, Alert, AlertTitle } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const CFDCalculator = () => {
  const [totalFunds, setTotalFunds] = useState('10000');
  const [blockedFunds, setBlockedFunds] = useState('5000');
  const [quantity, setQuantity] = useState('100');
  const [currentPrice, setCurrentPrice] = useState('50');
  const [simulatedPrice, setSimulatedPrice] = useState('55');
  const [positionType, setPositionType] = useState('long');
  const [accountStatus, setAccountStatus] = useState(0);
  const [liquidationPrice, setLiquidationPrice] = useState(0);
  const [pnl, setPnl] = useState({ amount: 0, percentage: 0 });
  const [alert, setAlert] = useState({ show: false, message: '', type: '' });
  const [tabValue, setTabValue] = useState(0);

  const parseNumber = (value) => (value === '' ? 0 : parseFloat(value));

  const calculateAccountStatus = () => {
    const total = parseNumber(totalFunds);
    const blocked = parseNumber(blockedFunds);
    if (total <= 0 || blocked <= 0) return 0;
    const ratio = total / (total + blocked);
    return ratio > 0.5 ? (total / (total + blocked)) * 100 : (total / blocked) * 50;
  };

  const calculateLiquidationPrice = () => {
    const criticalMargin = 0.25;
    const maxLoss = parseNumber(totalFunds) * (1 - criticalMargin);
    return positionType === 'long'
      ? parseNumber(currentPrice) - maxLoss / parseNumber(quantity)
      : parseNumber(currentPrice) + maxLoss / parseNumber(quantity);
  };

  const calculatePnL = (priceToCheck) => {
    const positionValue = parseNumber(quantity) * parseNumber(currentPrice);
    const newValue = parseNumber(quantity) * priceToCheck;
    const pnlAmount = positionType === 'long' ? newValue - positionValue : positionValue - newValue;
    const pnlPercentage = (pnlAmount / positionValue) * 100;
    return { amount: pnlAmount, percentage: pnlPercentage };
  };

  const getAlert = (status) => {
    if (status < 25) return { show: true, message: 'Account status below 25%. Positions will be automatically closed.', type: 'error' };
    if (status < 45) return { show: true, message: 'Margin call warning below 45%. Please add funds.', type: 'warning' };
    if (status < 50) return { show: true, message: 'Cannot open new positions. All funds are utilized.', type: 'info' };
    return { show: false, message: '', type: '' };
  };

  useEffect(() => {
    const status = calculateAccountStatus();
    const liqPrice = calculateLiquidationPrice();
    const currentPnL = calculatePnL(parseNumber(simulatedPrice));
    setAccountStatus(status);
    setLiquidationPrice(liqPrice);
    setPnl(currentPnL);
    setAlert(getAlert(status));
  }, [totalFunds, blockedFunds, quantity, currentPrice, simulatedPrice, positionType]);

  return (
    <Card sx={{ maxWidth: 600, margin: '0 auto', padding: 2 }}>
      <CardHeader title="CFD Position Calculator" />
      <CardContent>
        <Tabs value={tabValue} onChange={(e, newValue) => setTabValue(newValue)}>
          <Tab label="Position Details" />
        </Tabs>

        {tabValue === 0 && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6">Account Details</Typography>
            <TextField
              label="Total Funds"
              type="number"
              fullWidth
              variant="outlined"
              value={totalFunds}
              onChange={(e) => setTotalFunds(e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              label="Blocked Funds"
              type="number"
              fullWidth
              variant="outlined"
              value={blockedFunds}
              onChange={(e) => setBlockedFunds(e.target.value)}
              sx={{ mb: 2 }}
            />
            <Typography variant="h6">Position Details</Typography>
            <TextField
              label="Position Type"
              select
              fullWidth
              variant="outlined"
              value={positionType}
              onChange={(e) => setPositionType(e.target.value)}
              SelectProps={{ native: true }}
              sx={{ mb: 2 }}
            >
              <option value="long">Long</option>
              <option value="short">Short</option>
            </TextField>
            <TextField
              label="Quantity"
              type="number"
              fullWidth
              variant="outlined"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              label="Entry Price"
              type="number"
              fullWidth
              variant="outlined"
              value={currentPrice}
              onChange={(e) => setCurrentPrice(e.target.value)}
              sx={{ mb: 2 }}
            />
            <Typography variant="body1" sx={{ mt: 2 }}>
              Account Status: {accountStatus.toFixed(2)}%
            </Typography>
            <Box sx={{ height: 10, backgroundColor: 'grey.300', borderRadius: 2, mt: 1, overflow: 'hidden' }}>
              <Box sx={{ width: `${Math.min(100, accountStatus)}%`, height: '100%', backgroundColor: accountStatus > 50 ? 'green' : 'red' }} />
            </Box>
            <Typography variant="body1" sx={{ mt: 1 }}>
              Liquidation Price: {liquidationPrice.toFixed(2)}
            </Typography>

            {/* P&L Simulator Section */}
            <Box sx={{ mt: 4 }}>
              <Typography variant="h6">P&L Simulator</Typography>
              <TextField
                label="Simulated Price"
                type="number"
                fullWidth
                variant="outlined"
                value={simulatedPrice}
                onChange={(e) => setSimulatedPrice(e.target.value)}
                sx={{ mb: 2 }}
              />
              <Box sx={{ p: 2, backgroundColor: 'grey.100', borderRadius: 2 }}>
                <Typography variant="body2">Position Value: {(parseNumber(quantity) * parseNumber(currentPrice)).toFixed(2)}</Typography>
                <Typography variant="body2">Simulated Value: {(parseNumber(quantity) * parseNumber(simulatedPrice)).toFixed(2)}</Typography>
                <Typography variant="h6" sx={{ mt: 1, color: pnl.amount >= 0 ? 'green' : 'red' }}>
                  Profit/Loss: {pnl.amount.toFixed(2)} ({pnl.percentage.toFixed(2)}%)
                </Typography>
              </Box>
            </Box>
          </Box>
        )}

        {alert.show && (
          <Alert severity={alert.type} icon={<WarningAmberIcon />} sx={{ mt: 3 }}>
            <AlertTitle>{alert.type === 'error' ? 'Error' : alert.type === 'warning' ? 'Warning' : 'Info'}</AlertTitle>
            {alert.message}
          </Alert>
        )}
      </CardContent>
    </Card>
  );
};

export default CFDCalculator;
